import * as React from 'react';
import ProductCategories from './modules/views/ProductCategories';
import ProductSmokingHero from './modules/views/ProductSmokingHero';
import AppFooter from './modules/views/AppFooter';
import ProductHero from './modules/views/ProductHero';
import ProductValues from './modules/views/ProductValues';
import ProductHowItWorks from './modules/views/ProductHowItWorks';
import ProductCTA from './modules/views/ProductCTA';
import AppAppBar from './modules/views/AppAppBar';
import withRoot from './modules/withRoot';
import {Outlet} from "react-router-dom";
import Kontakt from "./ContactBox";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {useToast} from "../components/Toast/useToast";
import {InfoToast} from "../components/Toast/InfoToast";
import InfoBar from "../components/Toast/InfoBar";

function Index() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh', // Minimální výška na celou obrazovku
            }}
        >
            {/* Horní navigace */}
            <AppAppBar/>

            {/*<InfoToast initialOpen={new Date() < new Date("2025-02-14")}*/}
            {/*           message={"💖 Využijte Valentýnskou slevu 20 % a darujte něco výjimečného! Překvapte svou drahou polovičku originálním dárkem, který řekne více než tisíc slov. 🌹✨"}*/}
            {/*           localStorageKey={"info"}/>*/}
            <InfoBar
                endDateTime={'2025-02-14 23:59'}
                message={
                    <>
                        🌹 Překvapte své nejbližší originálním dárkem! 💖 Valentýnská sleva 20 % s kódem <strong>LOVE20</strong> ✨
                    </>
                }
                textColor="primary.main"
            />
            {/* Hlavní obsah */}
            <Outlet/>

            {/* Footer */}
            <AppFooter/>
        </Box>
    );
}

export default withRoot(Index);
