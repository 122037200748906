import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import StarIcon from '@mui/icons-material/Star';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BrushIcon from '@mui/icons-material/Brush';
import Button from '@mui/material/Button';
import MetalImageGallery from "./MetalImageGallery";
import Grid from "@mui/material/Grid2";
import {useNavigate} from "react-router-dom";
import Link from "@mui/material/Link";
import LinkButton from "../onepirate/modules/components/LinkButton";

const WhyMetalPrintPage: React.FC = () => {
    const navigate = useNavigate();

    const handleViewProduct = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        window.scrollTo(0, 0);
        navigate("/products");
        //setOpen(true);
    };

    return (
        <Container sx={{ paddingTop: 10, paddingBottom: 20 }}>
            <Helmet>
                <title>Proč si vybrat metalický obraz? - PrintKing.cz</title>
                <link rel="canonical" href="https://www.printking.cz/metalicky-obraz" />
                <meta
                    name="description"
                    content="Zjistěte, proč jsou metalické obrazy ideální volbou pro váš interiér. Luxusní design, dlouhá životnost a možnost personalizace. Objevte všechny výhody metalických obrazů od PrintKing.cz."
                />
                <meta
                    name="keywords"
                    content="metalické obrazy, kovové obrazy, hliníkové obrazy, luxusní obrazy, moderní dekorace, fotoobrazy, personalizované obrazy, interiérové dekorace, metalický fotoobraz, PrintKing.cz"
                />
                <meta property="og:title" content="Proč si vybrat metalický obraz? - PrintKing.cz" />
                <meta
                    property="og:description"
                    content="Zjistěte, proč jsou metalické obrazy ideální volbou pro váš interiér. Nabízíme dlouhou životnost, moderní design a možnost personalizace. Objevte všechny výhody metalických obrazů od PrintKing.cz."
                />
                <meta property="og:url" content="https://www.printking.cz/metalicky-obraz" />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Proč si vybrat metalický obraz? - PrintKing.cz" />
                <meta
                    name="twitter:description"
                    content="Zjistěte, proč jsou metalické obrazy ideální volbou pro váš interiér. Luxusní design, dlouhá životnost a možnost personalizace. Objevte všechny výhody metalických obrazů od PrintKing.cz."
                />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Proč si vybrat metalický obraz? - PrintKing.cz",
                        "url": "https://www.printking.cz/metalicky-obraz",
                        "description":
                            "Zjistěte, proč jsou metalické obrazy ideální volbou pro váš interiér. Luxusní design, dlouhá životnost a možnost personalizace. Objevte všechny výhody metalických obrazů od PrintKing.cz.",
                        "publisher": {
                            "@type": "Organization",
                            "name": "PrintKing.cz",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "%PUBLIC_URL%/logo192.png",
                            },
                        },
                        "breadcrumb": {
                            "@type": "BreadcrumbList",
                            "itemListElement": [
                                {
                                    "@type": "ListItem",
                                    "position": 1,
                                    "name": "Domů",
                                    "item": "https://www.printking.cz/"
                                },
                                {
                                    "@type": "ListItem",
                                    "position": 2,
                                    "name": "Produkty",
                                    "item": "https://www.printking.cz/products"
                                },
                                {
                                    "@type": "ListItem",
                                    "position": 3,
                                    "name": "Výhody obrazu",
                                    "item": "https://www.printking.cz/metalicky-obraz"
                                },
                                {
                                    "@type": "ListItem",
                                    "position": 4,
                                    "name": "Kontakt",
                                    "item": "https://www.printking.cz/contact"
                                }
                            ]
                        },
                    })}
                </script>
            </Helmet>



            <Typography variant="h3" component="h1" align="center" gutterBottom>
                Proč si vybrat metalický obraz?
            </Typography>
            <Typography
                variant="h6"
                align="center"
                sx={{ marginBottom: 4, color: 'text.secondary' }}
            >
                Kombinace elegantního designu, dlouhé životnosti a jedinečné kvality, která oživí váš interiér.
            </Typography>

            <Grid container spacing={4} pt={4} pb={4}>
                <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                    <Box sx={{ textAlign: 'center', padding: 2 }}>
                        <StarIcon sx={{ fontSize: '3rem', color: 'primary.main' }} />
                        <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: 2 }} pb={2}>
                            Nezničitelná kvalita
                        </Typography>
                        <Typography variant="body1">
                            Naše kovové obrazy jsou odolné vůči poškrábání a vlhkosti. Speciální povrchová úprava
                            zaručuje stálost barev po dlouhá léta.
                        </Typography>
                    </Box>
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                    <Box sx={{ textAlign: 'center', padding: 2 }}>
                        <CheckCircleIcon sx={{ fontSize: '3rem', color: 'primary.main' }} />
                        <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: 2 }} pb={2}>
                            Ohromující detaily
                        </Typography>
                        <Typography variant="body1">
                            Sublimační tisk zajišťuje živé barvy, hluboké kontrasty a precizní detaily na každém obrazu.
                        </Typography>
                    </Box>
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                    <Box sx={{ textAlign: 'center', padding: 2 }}>
                        <BrushIcon sx={{ fontSize: '3rem', color: 'primary.main' }} />
                        <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: 2 }} pb={2}>
                            Personalizace
                        </Typography>
                        <Typography variant="body1">
                            Vyberte si z naší široké nabídky velikostí a vytvořte vlastní fotoobraz.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>


            <Box sx={{ textAlign: 'center', marginTop: 6 }} pb={4}>
                <Typography variant="h5" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
                    Vytvořte si vlastní unikátní dekoraci!
                </Typography>
                <LinkButton to="/products" size="large" title={"Vytvořte si vlastní metalický fotoobraz."}>
                    Navrhněte vlastní obraz
                </LinkButton>
            </Box>

            <Box sx={{ marginTop: 8}}>
                <MetalImageGallery />
            </Box>
        </Container>
    );
};

export default WhyMetalPrintPage;
